<template>
  <sub-page
      :title="$t('Storage')"
      icon="mdi-cogs"
      v-model="$store.state.settings.pageData"
  >
    <ws-accordion
        class="mt-5"
        :items="navigationItems"
    >
      <!-- Actions-->
      <template #action.enable_student_storage>
        <ft-select
            :value="$store.state.business.selectedBusiness.enable_student_storage"
            @input="editStudentStorage"
            :items="CHOICE_ACTIVE"
            class="pointer"
        >
          <div style="min-width: 150px" class="d-flex justify-end">
          <v-chip
              dark
              :color="wsACCENT"
              :style="!$store.state.business.selectedBusiness.enable_student_storage ? 'opacity : 0.5' : ''"
              class="px-1 justify-end font-weight-medium pointer mr-5"
          >
            <v-icon  class="mr-1" >
              {{  !$store.state.business.selectedBusiness.disable_public ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline' }}
            </v-icon>
            <h5 class="mx-2">{{ $store.state.business.selectedBusiness.enable_student_storage ? $t('Active') : $t('NotActive')   }}</h5>
            <v-icon>mdi-menu-down</v-icon>
          </v-chip>
          </div>
        </ft-select>
      </template>

    </ws-accordion>

  </sub-page>
</template>

<script>
import languagesList from "@/mixins/languagesList";
import {mapActions} from "vuex";

export default {
  name: "settingsStorage",
  mixins : [languagesList],
  components : {
  },
  data() {
    return {
      serviceKeys : {},
      // alias
      alias : '',
      aliasError : null,
      aliasConfirmationError : null,
      aliasConfirmationCode : null,
      aliasConfirmation : null,
      displayAliasDialog : false,
      // description
      langs  : [],
      names : {},
      descriptions : {},
      displayLangDialog : false,
      selectedLang: null,
      langEntityData : {},
      newLang : false,
      timeZones : [],
      countries : [],
      timezone : null
    }
  },
  computed : {
    navigationItems() {
      return [
        { name : this.$t('settings.storage.students.title') ,
          subtitle : this.$t('settings.storage.students.tooltip'),
          value : 'enable_student_storage' ,
          icon : 'mdi-school-outline',
          expand : false,
          noExpand: true,
          expanded : false
        },
      ]
    },
  },
  methods : {
    ...mapActions('settings',  [
        'EDIT_BUSINESS_GENERAL_SETTINGS',
    ]),

    async editStudentStorage(value){
      let result = await this.EDIT_BUSINESS_GENERAL_SETTINGS({ enable_student_storage : value })
      if ( !result ) {
        return
      }
      this.$store.state.business.selectedBusiness.enable_student_storage = value
    },

  },
  mounted() {
  }
}
</script>

<style scoped>

</style>